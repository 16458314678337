<script>
  import { createNamespacedHelpers } from 'vuex'
  import isEqual from 'lodash/isEqual'
  import BaseSelect from '@/components/BaseSelect'
  import BaseSlider from '@/components/BaseSlider'
  import BaseToggleButtons from '@/components/BaseToggleButtons'
  import ProductActions from '@/components/products/ProductActions'
  import ProductInfo from '@/components/products/ProductInfo'
  import ProductSpecs from '@/components/products/ProductSpecs'
  import ProductStory from '@/components/products/ProductStory'
  import ProductSwatchOptions from '@/components/products/ProductSwatchOptions'
  import BackIcon from '@/images/icons/back-icon.svg'
  import PlusIcon from '@/images/icons/plus-sign.svg'
  import MinusIcon from '@/images/icons/minus-sign.svg'
  import ProductDescription from '@/components/products/ProductDescription'
  import ProductDownloads from '@/components/products/ProductDownloads'

  const { mapGetters: mapListsGetters } = createNamespacedHelpers('lists')
  const { mapState, mapActions } = createNamespacedHelpers('products')
  const { mapGetters: mapGlobalGetters } = createNamespacedHelpers('global')

  /** This is the view template for the Product Detail Page (PDP). It mostly pulls in other templates. */

  export default {
    name: 'ProductDetailPage',

    components: {
      BackIcon,
      PlusIcon,
      MinusIcon,
      ProductDescription,
      BaseSelect,
      BaseSlider,
      BaseToggleButtons,
      ProductActions,
      ProductInfo,
      ProductSpecs,
      ProductStory,
      ProductSwatchOptions,
      ProductDownloads,
    },

    props: {
      /** The main Craft product object */
      product: {
        type: Object,
        required: true,
      },
      popupIframe:{
        type:String,
        default:''
      },
      /** An array of the products variants */
      variants: {
        type: Array,
        required: true,
      },
      thumb: {
        type: String,
        default: '',
      },
      /** Passed to ProductDescription */
      description: {
        type: String,
        required: false,
        default: '',
      },
      /** Passed to data and used for the product builder */
      initialVariantAttributes: {
        type: Array,
        required: true,
      },
      externalLinks: {
        type: Array,
        required: true,
      },
      /** Used for the product builder */
      initialPriceNeutralAttributes: {
        type: Array,
        required: true,
      },
      /** The products categories */
      categories: {
        type: Array,
        default: () => [],
      },
      /** Passed to ProductInfo */
      tags: {
        type: Array,
        default: () => [],
      },
      /** Passed to BaseSlider */
      slides: {
        type: Array,
        default: () => [],
      },
      /** Passed to ProductSpecs */
      specs: {
        type: Object,
        default: () => ({}),
      },
      dimensions: {
        type: Array,
        default: () => [],
      },
      /** Passed to Product Specs */
      downloads: {
        type: Array,
        default: () => [],
      },
      otherDownloads: {
        type: Array,
        default: () => [],
      },
      /** The products brand object */
      brand: {
        type: Object,
        required: true,
      },
      /** The products designer pbject */
      designer: {
        type: Object,
        default: () => ({}),
      },
      /** Passed to ProductInfo */
      collection: {
        type: Object,
        default: () => ({}),
      },
      /** Passed to ProductActions */
      form: {
        type: Object,
        required: true,
      },
      pconForm: {
        type: Object,
        required: true,
      },
      priceForm: {
        type: Object,
        required: true,
      },
      /** Passed to ProductActions */
      urls: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        textOrCut: false,
        /** We don't want to directly mutate any prop, so we're creating variantAttributes and
         * priceNeutralAttributes from initialVariantAttributes and initialPriceNeutralAttributes, \
         * and adding in a selectedSwatch property that Craft doesn't care about but Vue does. */
        variantAttributes: this.initialVariantAttributes.map((attribute) => ({
          ...attribute,
          selectedSwatch: null,
        })),
        priceNeutralAttributes: this.initialPriceNeutralAttributes.map((attribute) => ({
          ...attribute,
          selectedSwatch: null,
        })),
        category: {},
        readmore: false,
        letters: 350,
        specExtensions: [],
        otherExtensions: [],
      }
    },

    computed: {
      ...mapState(['selectedLabel']),
      ...mapGlobalGetters(['isTradeAccount']),
      ...mapListsGetters(['getSavedProducts']),
      getPdfOnlyDownloads() {
        return this.downloads.filter((item) => item.extension === 'pdf')
      },
      designerObjects() {
        return Object.keys(this.designer).length
      },
      brandObjects() {
        return Object.keys(this.brand).length
      },
      /** The variant that will be added to the cart (or sent in the quote request) */
      purchasableVariant() {
        /** Array of option IDs for variant attributes (affect the price) */
        const selectedVariantAttributeOptionIds = this.variantAttributes
          .map((attribute) => attribute.selectedOptionId)
          .sort()

        /** Find the variant that matches the selected options using https://lodash.com/docs/4.17.15#isEqual */
        return this.variants.find((variant) =>
          isEqual(variant.attributeIds.sort(), selectedVariantAttributeOptionIds)
        )
      },

      /** Human-readable list of every choice the customer has made. An object with Label: Option pairs. */
      allOptions() {
        /** Combine all variant and price neutral attributes into a single array */
        const allAttributes = this.variantAttributes.concat(this.priceNeutralAttributes)
        /** Go through the array to create a new array of label:option pairs for each attribute */
        return allAttributes.reduce((options, attribute) => {
          if (attribute.selectedSwatch) {
            options[attribute.label] = attribute.selectedSwatch.title
          } else if (attribute.selectedOptionId) {
            const option = attribute.options.find(
              (option) => option.id === attribute.selectedOptionId
            )
            options[attribute.label] = option.label
          } else {
            options[attribute.label] = 'Not selected'
          }
          return options
        }, {})
      },
      allOptionsDetailed() {
        const allAttributes = this.variantAttributes.concat(this.priceNeutralAttributes)
        return allAttributes.reduce((options, attribute) => {
          let option = {
            image: null,
            label: null,
            name: null,
            fabric: null
          }
          if (attribute.selectedSwatch) {
            option.label = attribute.selectedSwatch.collectionLabel;
            option.image = attribute.selectedSwatch.image || null
            option.name = attribute.selectedSwatch.name
            option.fabric = attribute.label
          } else if (attribute.selectedOptionId) {
            const selectedOption = attribute.options.find(
              (opt) => opt.id === attribute.selectedOptionId
            );
            option.label = attribute.label
            option.name = selectedOption.label
            option.image = selectedOption.image || null
            option.fabric = attribute.label
          } else {
            option.label = 'Not selected';
            option.image = null;
          }

          options.push(option)
          return options
        }, []);
      },
      /** True if variant or any of its options are already saved test*/
      isSaved() {
        return this.getSavedProducts.some((listItem) => {
          if (listItem.id !== this.purchasableVariant?.id) {
            return false
          }

          const normalizedListItemOptions = Object.fromEntries(
            listItem.options.map((option) => [option.label, option.value])
          )
          const allOptions = Object.assign({}, this.allOptions) // Remove vue observer property

          const equal = isEqual(normalizedListItemOptions, allOptions)
          return equal
        })
      },
      categoryString: function () {
        const catArray = []
        this.categories.forEach((category) => {
          catArray.push(category.title)
        })
        return catArray.join('/')
      },
      getSpecTags() {
        // Chicago Showroom, New York Showroom, UL Listed, ETL Listed
        const excludeIds = [616546, 582594, 582595, 582596, 582598, 845357, 846047]
        return this.tags.filter((tag) => !excludeIds.includes(tag.id))
      },
    },

    watch: {
      variantAttributes: {
        immediate: true,
        deep: true,
        handler() {
          if (!this.purchasableVariant) {
            window.alert('Sorry, this combination isn’t available!')
          }
        },
      },
      textOrCut() {
        if (this.textOrCut) {
          this.readmore = true
        } else {
          this.readmore = false
        }
      },
    },

    created() {
      this.$store.dispatch('lists/loadItems')
    },

    mounted() {
        
      if (this.purchasableVariant && Object.entries(this.purchasableVariant).length !== 0) {
        const selectId = this.purchasableVariant.attributeIds[0]
        this.setFirstOptionSelected(selectId)
      }
      this.category = {
        title: this.categories[this.categories.length - 1].title,
        url: this.categories[this.categories.length - 1].url,
      }
      const tabs = document.querySelectorAll('.productTabs__tab')
      const tabcontent = document.querySelectorAll('.productTabs__content')
      for (let i = 0; i < tabs.length; i++) {
        tabs[i].onclick = function () {
          tabs[i].classList.toggle('active')
          tabcontent[i].classList.toggle('active')
        }
      }

      // Send the appropriate GTM event
      if (this.$gtm.enabled()) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'View_Product',
          email: this.$store.getters['global/currentUser']
            ? this.$store.getters['global/currentUser'].email
            : '',
          product_id: this.product.id,
          product_category: this.categoryString,
          product_name: this.product.title,
        })
      }
    },

    methods: {
      ...mapActions(['setFirstOptionSelected']),
      /** Handler for when the ProductSwatchOptions component changes its value */
      goBack() {
        let filterIsOpen = localStorage.getItem('filterEnable')
        if (filterIsOpen) {
          localStorage.setItem('filterHash', 'openedfilter')
          localStorage.removeItem('filterEnable')
        }
        setTimeout(() => {
          history.back()
        }, 500)
      },
      testClick() {
        const url = new URL(window.location)
        url.searchParams.set('s', '123')
        window.history.pushState({}, '', url)
      },
      updateSelectedSwatch($event, attribute) {
        attribute.selectedSwatch = {
          id: $event.swatchId,
          title: $event.swatchTitle,
          image: $event.swatchImage,
          collectionLabel: $event.swatchLabel,
          name: $event.swatchName
        }
        attribute.selectedOptionId = $event.optionId
      },
      /** Add recently viewed product to local storage */
      setRecentlyViewed() {
        let updatedRecentlyViewed

        const recentlyViewedFromStorage = localStorage.getItem('recently-viewed')
        if (recentlyViewedFromStorage === null) {
          updatedRecentlyViewed = [this.product]
        } else {
          const recentlyViewed = JSON.parse(recentlyViewedFromStorage)
          const alreadyViewed = recentlyViewed.find((item) => item.id === this.product.id)
          if (alreadyViewed) {
            updatedRecentlyViewed = recentlyViewed
          } else {
            updatedRecentlyViewed = [this.product, ...recentlyViewed]
          }
        }
        localStorage.setItem('recently-viewed', JSON.stringify(updatedRecentlyViewed))
      },
    },
  }
</script>

<template>
  <main id="main-content">
    <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6 pt-12 lg:pt-0">
      <div class="backToCatalog w-full mb-8 lg:pt-20">
        <a
          href="#"
          @click.prevent="goBack"
          class="uppercase inline-flex flex-row items-center font-bold text-sm text-gray-600 leading-none"
        >
          <BackIcon />
          <span class="leading-none tracking-widest pl-3">Back to Catalogue</span>
        </a>
      </div>
      <h4 @click="testClick" class="hidden" ref="backTrigger">Back Trigger</h4>
      <article
        class="flex flex-col space-y-12 lg:space-y-0 lg:flex-row pb-12 md:pb-40"
        :data-cy="purchasableVariant ? purchasableVariant.id : undefined"
      >
        <div class="w-full lg:w-7/12 xl:w-8/12 pr-0 lg:pr-4">
          <!--  TODO: Add purchasable images -->
          <BaseSlider :slides="slides"></BaseSlider>
        </div>

        <div class="w-full lg:w-5/12 xl:w-4/12 pl-0 lg:pl-4">
          <div>
            <ProductInfo
              :product-title="product.title"
              :brand="brand"
              :designer="designer"
              :collection="collection"
              :tags="getSpecTags"
              :selected-variant-id="purchasableVariant ? purchasableVariant.id : undefined"
              :variants="variants"
              :is-available="product.isAvailable"
              :description="product.description"
            ></ProductInfo>
          </div>

          <!-- Quickship Variants will go here
          <section class="py-8 border-b border-gray-300"></section>
          -->

          <div v-if="product.description" class="mb-6">
            <ProductDescription
              :text="product.description"
              :product-title="product.title"
              :collection-name="collection.title"
              :collection-slug="collection.slug"
              :brand="brand"
            />
          </div>

          <section v-if="variantAttributes.length || priceNeutralAttributes.length">
            <div v-for="attribute in variantAttributes" :key="attribute.id">
              <div class="mb-4">
                <BaseSelect
                  v-if="attribute.fieldType === 'dropdownMenu'"
                  v-model="attribute.selectedOptionId"
                  :label="attribute.label"
                  :is-required="true"
                  :options="attribute.options"
                ></BaseSelect>

                <BaseToggleButtons
                  v-else-if="attribute.fieldType === 'textButtons'"
                  v-model="attribute.selectedOptionId"
                  :label="attribute.label"
                  :is-required="true"
                  :options="attribute.options"
                ></BaseToggleButtons>

                <ProductSwatchOptions
                  v-else
                  :type="attribute.fieldType === 'singleSwatches' ? 'single' : 'collections'"
                  :label="attribute.label"
                  :is-required="true"
                  :options="attribute.options"
                  :value="attribute.selectedOptionId"
                  @input="updateSelectedSwatch($event, attribute)"
                ></ProductSwatchOptions>
              </div>
            </div>

            <div v-for="attribute in priceNeutralAttributes" :key="attribute.id">
              <div class="mb-4">
                <BaseSelect
                  v-if="attribute.fieldType === 'dropdownMenu'"
                  v-model="attribute.selectedOptionId"
                  :label="attribute.label"
                  :is-required="attribute.isRequired"
                  :options="attribute.options"
                ></BaseSelect>

                <BaseToggleButtons
                  v-else-if="attribute.fieldType === 'textButtons'"
                  v-model="attribute.selectedOptionId"
                  :label="attribute.label"
                  :is-required="attribute.isRequired"
                  :options="attribute.options"
                ></BaseToggleButtons>

                <ProductSwatchOptions
                  v-else
                  :type="attribute.fieldType === 'singleSwatches' ? 'single' : 'collections'"
                  :label="attribute.label"
                  :is-required="attribute.isRequired"
                  :options="attribute.options"
                  :value="attribute.selectedOptionId"
                  @input="updateSelectedSwatch($event, attribute)"
                ></ProductSwatchOptions>
              </div>
            </div>
          </section>

          <ProductActions
            :is-available-for-purchase="product.isAvailable"
            :product="product"
            :variant="purchasableVariant"
            :categories="categories"
            :all-options="allOptions"
            :all-options-detailed="allOptionsDetailed"
            :brand="brand"
            :form="form"
            :pcon-form="pconForm"
            :popup-iframe="popupIframe"
            :variants="variants"
            :price-form="priceForm"
            :urls="urls"
            :is-saved="isSaved"
            :thumb="thumb"
          />
          <div class="productTabs w-full">
            <div ref="tabs" class="w-full mb-0">
              <h3
                class="productTabs__tab bg-transparent border-b border-gray-300 text-black font-unica77 text-sm font-medium uppercase block w-full tracking-widest relative py-4"
              >
                <span class="block showTabContent">
                  <PlusIcon class="plusIcon" />
                  <MinusIcon class="minusIcon" />
                </span>
                Specs
              </h3>
              <div class="productTabs__content bg-white text-black px-0">
                <ProductSpecs
                  v-if="dimensions.length > 0"
                  :downloads="[]"
                  :dimensions="dimensions"
                  :show-new-layout="true"
                >
                  <template v-slot:materials>
                    <div v-if="specs.materials" v-html="specs.materials"></div>
                  </template>
                  <template v-slot:finishes>
                    <div v-if="specs.finishes" v-html="specs.finishes"></div>
                  </template>
                </ProductSpecs>
                <ProductSpecs v-else :show-new-layout="false">
                  <template v-slot:dimensions>
                    <div v-if="specs.dimensions" v-html="specs.dimensions" class="text-sm"></div>
                  </template>
                  <template v-slot:materials>
                    <div v-if="specs.materials" v-html="specs.materials" class="text-sm"></div>
                  </template>
                  <template v-slot:finishes>
                    <div v-if="specs.finishes" v-html="specs.finishes" class="text-sm"></div>
                  </template>
                </ProductSpecs>
              </div>
            </div>
            <div v-if="downloads.length > 0" ref="tabs" class="w-full mb-0">
              <h3
                class="productTabs__tab bg-transparent border-b border-gray-300 text-black font-unica77 font-medium uppercase block w-full tracking-widest relative py-4"
              >
                <span class="block showTabContent">
                  <PlusIcon class="plusIcon" />
                  <MinusIcon class="minusIcon" />
                </span>
                Downloads
              </h3>
              <div class="productTabs__content bg-white text-black">
                <ProductDownloads
                  :downloads="getPdfOnlyDownloads"
                  :external-links="externalLinks"
                  :other-downloads="otherDownloads"
                  class="text-black"
                />
              </div>
            </div>
            <div v-if="brandObjects > 0" ref="tabs" class="w-full mb-0">
              <h3
                class="productTabs__tab bg-transparent text-black border-b border-gray-300 font-unica77 font-medium uppercase block w-full tracking-widest relative py-4"
              >
                <span class="block showTabContent">
                  <PlusIcon class="plusIcon" />
                  <MinusIcon class="minusIcon" />
                </span>
                Brand
              </h3>
              <div class="productTabs__content bg-white text-black">
                <ProductStory :label="'Brand'" :title="brand.title" :logo="brand.logo">
                  <div class="wysiwyg text-sm text-black" v-html="brand.description"></div>
                </ProductStory>
              </div>
            </div>
            <div v-if="designerObjects > 0" ref="tabs" class="w-full mb-0">
              <h3
                class="productTabs__tab bg-transparent text-black border-b border-gray-300 font-unica77 font-medium uppercase block w-full tracking-widest relative py-4"
              >
                <span class="block showTabContent">
                  <PlusIcon class="plusIcon" />
                  <MinusIcon class="minusIcon" />
                </span>
                Designer
              </h3>
              <div class="productTabs__content bg-white text-black">
                <ProductStory
                  :label="'Designer'"
                  :title="designer.title"
                  :logo="designer.image.urls[1]"
                >
                  <div class="wysiwyg text-sm text-black" v-html="designer.description"></div>
                </ProductStory>
              </div>
            </div>
          </div>
        </div>
      </article>
      <!-- <div
        v-if="contentIframe"
        class="w-full mb-24 iframe--holder"
        v-html="contentIframe"
      ></div> -->
    </div>
  </main>
</template>

<style scoped lang="postcss">
  .iframe--holder {
    height: 650px;
    >>> iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .wysiwyg a {
    border-color: #6f6f6f;
    border-style: solid;
  }

  .wysiwyg {
    color: black;
  }

  .wysiwyg.text-black p {
    color: black;
  }

  .wysiwyg a.download--btn {
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    color: #ffdf00;
    border-color: #ffdf00;
    border-style: solid;

    &:hover {
      border-color: transparent;
    }
  }

  #main-content {
    overflow: hidden;
  }

  .collect--btn {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    color: rgb(202, 24, 24);
    border-bottom: 1px solid rgb(202, 24, 24);

    &:hover {
      border-color: transparent;
    }
  }

  .backToCatalog {
    span {
      line-height: 14px;
      font-size: 14px;
    }
  }

  .readMoreBlock {
    position: relative;

    &.content-clamp {
      &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        height: 50%;
        content: '';
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(255, 255, 255, 0)),
          to(rgba(255, 255, 255, 1))
        );
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
      }
    }
  }

  >>> .descrTextBlock {
    position: relative;
    display: block;
    padding-top: 20px;
    overflow: hidden;
    font-size: 14px;
    line-height: 130%;
    color: #6f6f6f;

    p,
    strong,
    span,
    em,
    h1,
    h2 {
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      color: #6f6f6f;
    }

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .productTabs {
    h3 {
      font-size: 14px;
    }
    &__content {
      display: none;

      &.active {
        display: block;
      }
    }

    &__tab {
      cursor: pointer;

      .minusIcon {
        display: none;
      }

      &.active {
        .minusIcon {
          display: block;
        }

        .plusIcon {
          display: none;
        }
      }

      .showTabContent {
        position: absolute;
        top: 50%;
        right: 20px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        color: #fff;
        transform: translateY(-50%);
      }
    }
  }
</style>
