<script>
import CollectionHeader from "@/components/collections/CollectionHeader";
import ProductTile from '@/components/products/ProductTile'

export default {
  name: "CollectionDetailPage",

  components: {
    CollectionHeader,
    ProductTile
  },

  props: {
    title: {
      type: String,
      default: "",
      required: false,
    },
    brandInfo: {
      type: Object,
      default: () => ({}),
    },
    products: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isLoading: true,
      paginatedProducts: [],
      currentPage: 1,
      productsPerPage: 24,
    };
  },

  computed: {
    getIsLoading() {
      return this.isLoading;
    },
    isLoaded() {
      return !this.isLoading && this.products.length === 0;
    },
    isLastPage() {
      return this.paginatedProducts.length >= this.products.length;
    },
    totalQuantity() {
      return this.products.length;
    },
    shownQuantity() {
      return this.paginatedProducts.length;
    },
  },

  watch: {
    products: {
      handler(newProducts) {
        this.paginatedProducts = newProducts.slice(0, this.productsPerPage);
        this.isLoading = false;
      },
      immediate: true,
    },
  },

  methods: {
    nextPage() {
      const nextProducts = this.products.slice(
        0,
        this.paginatedProducts.length + this.productsPerPage
      );
      this.paginatedProducts = nextProducts;
    },
  },
};
</script>

<template>
  <main id="main-content">
    <article class="plp">
      <CollectionHeader v-if="title" :title="title" :brand="brandInfo" />

      <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
        <div class="static flex flex-col md:flex-row md:relative flex-wrap overflow-hidden mb-16 md:mb-8">
          <div class="w-full pb-4">
            
            <!-- Loader -->
            <transition name="fade">
              <div v-if="getIsLoading" class="z-10 loader">
                <BaseTileGridLoader />
              </div>
            </transition>

            <!-- Product List -->
            <transition name="fade">
              <div v-if="!getIsLoading">
                <section v-if="paginatedProducts.length">
                  <div
                    class="grid grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 lg:grid-cols-4"
                    role="feed"
                  >
                    <ProductTile
                      v-for="product in paginatedProducts"
                      :key="product.id"
                      v-bind="product"
                      :destination="destination"
                      :hide-price="hidePrice"
                    />
                  </div>

                  <!-- Pagination Buttons -->
                  <div class="mt-4 md:mt-6" v-if="!isLastPage">
                    <nav
                      aria-label="Product pagination"
                      class="flex flex-col justify-center items-center -mb-12 md:-mb-0 pb-0 pt-4 underline underline-2 hover:no-underline | md:flex-row || wrapper"
                      :class="`${isLastPage ? 'hasNoLoadMore' : ''}`"
                    >
                      <span
                        class="text-gray-500 || desktop invisible"
                        v-html="'Showing ' + shownQuantity + ' Products'"
                      />

                      <transition name="fade">
                        <BaseButton                          
                          :color="'white'"
                          :rounded="true"
                          class="mb-2 | md:mb-0 bg-transparent border-0 underline hover:no-underline"
                          :button-class="`font-bold tracking-150 uppercase border-0 underline hover:no-underline`"
                          :additional-btn-classes="`font-bold tracking-150 uppercase border-0 underline hover:no-underline border-none`"
                          @button-click="nextPage"
                        >
                          <span
                            class="border-solid border-0 border-b border-black hover:border-transparent tracking-150 font-unica77 font-bold"
                            style="border-bottom-width: 2px"
                          >
                            See More
                          </span>
                        </BaseButton>
                      </transition>

                      <span
                        class="text-gray-500 || desktop invisible"
                        aria-label="total products"
                        v-html="'Total ' + totalQuantity + ' Products'"
                      />

                      <span
                        class="block text-xs text-gray-500 || mobile invisible"
                        aria-current="true"
                        aria-label="current products"
                        v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Products'"
                      />
                    </nav>
                  </div>
                </section>

                <div v-if="isLoaded" class="p-4 text-center md:p-6">
                  <h3 class="mb-2 font-bold text-lg">No Products Found</h3>
                  <p>Please try again by widening your search and filter settings.</p>
                </div>
              </div>
            </transition>

          </div>
        </div>
      </div>
    </article>
  </main>
</template>

<style scoped>
@media (max-width: 1024px) {
  .stickySidebar {
    margin-left: 0 !important;
  }
}

.loader {
  top: 16px;
  right: 16px;
  left: 16px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
